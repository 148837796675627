import { Grid } from '@/components/global/Grid';

import DynamicComponent from './DynamicComponent';

const ContainerWrapper = ({ blok: { columns, gutter, ...rest } }) => {
  return (
    <Grid gutter={gutter} {...rest}>
      {columns.map((blok) => (
        <DynamicComponent blok={blok} key={blok._uid} />
      ))}
    </Grid>
  );
};

export default ContainerWrapper;
